import create from "zustand"
import { toast } from 'react-toastify'
import { SuccessToast } from '@src/views/ui-elements/ToastContents'
import ToastConfig from "@src/configs/toastConfig"
import { Root } from "@configs/serverConfig"
import { dummyData } from "@configs/dataConfig"
import ConfigApi from "../api/ConfigApi"

const useStore = create(() => ({

  // Game Type
  getAllGameType: async (data) => {
    Root.show()
    const res = await ConfigApi.getAllGameType(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return dummyData
    }
  },
  submitGameType: async (data) => {
    Root.show()
    const res = await ConfigApi.submitGameType(data)
    Root.hide()
    if (res) {
      toast.success(<SuccessToast />, ToastConfig)
      return true
    } else {
      return false
    }
  },
  submitGameTypeOrder: async (data) => {
    Root.show()
    const res = await ConfigApi.submitGameTypeOrder(data)
    Root.hide()
    if (res) {
      toast.success(<SuccessToast />, ToastConfig)
      return true
    } else {
      return false
    }
  },
  downloadCSVGameType: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadCSVGameType(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },
  downloadEXCELGameType: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadEXCELGameType(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },

  //  ------------------------------------------------------------------
  // Game Category
  getAllGameCategories: async (data) => {
    Root.show()
    const response = await ConfigApi.getAllGameCategories(data)
    Root.hide()
    if (response) {
      return response.data
    }
    return dummyData
  },
  submitGameCategory: async (data) => {
    Root.show()
    const response = await ConfigApi.submitGameCategory(data)
    Root.hide()
    if (response) {
      toast.success(<SuccessToast />, ToastConfig)
    }
    return Boolean(response)
  },
  submitGameCategoryOrder: async (data) => {
    Root.show()
    const response = await ConfigApi.submitGameCategoryOrder(data)
    Root.hide()
    if (response) {
      toast.success(<SuccessToast />, ToastConfig)
    }
    return Boolean(response)
  },
  downloadCSVGameCategories: async (data) => {
    Root.show()
    const response = await ConfigApi.downloadCSVGameCategories(data)
    Root.hide()
    return response?.data || false
  },
  downloadEXCELGameCategories: async (data) => {
    Root.show()
    const response = await ConfigApi.downloadEXCELGameCategories(data)
    Root.hide()
    return response?.data || false
  },

  //  ------------------------------------------------------------------

  // Game Provider
  getAllGameTypesOfProvider: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllGameTypesOfProvider(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },
  getAllGameAgregator: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllGameAgregator(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },
  getAllProvider: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllProvider(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },
  submitGameProvider: async (data) => {
    Root.show()
    const res = await ConfigApi.submitGameProvider(data)
    Root.hide()
    if (res) {
      toast.success(<SuccessToast />, ToastConfig)
      return true
    } else {
      return false
    }
  },
  submitGameProviderOrder: async (data) => {
    Root.show()
    const res = await ConfigApi.submitGameProviderOrder(data)
    Root.hide()
    if (res) {
      toast.success(<SuccessToast />, ToastConfig)
      return true
    } else {
      return false
    }
  },
  downloadCSVGameProvider: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadCSVGameProvider(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },
  downloadEXCELGameProvider: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadEXCELGameProvider(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },

  getCurrencyList: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getCurrencyList(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return []
    }
  },
  getAllPriceList: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllPriceList(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return []
    }
  },
  getCountryList: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getCountryList(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return []
    }
  },

  //  ------------------------------------------------------------------

  // Games
  getAllGameProvidersOfGames: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllGameProvidersOfGames(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },
  getConditionProviders: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getConditionProviders(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },
  getAllGames: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllGames(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },
  submitGame: async (data) => {
    Root.show()
    const res = await ConfigApi.submitGame(data)
    Root.hide()
    if (res) {
      toast.success(<SuccessToast />, ToastConfig)
      return true
    } else {
      return false
    }
  },
  downloadCSVGames: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadCSVGames(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },
  downloadEXCELGames: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadEXCELGames(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },
  getAllTournamentList: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllLocalTournamentList(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },

  getAllSpecialGameTypeList: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllSpecialGameTypeList(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },

  submitGameOrder: async (data) => {
    Root.show()
    const res = await ConfigApi.submitGameOrder(data)
    Root.hide()
    if (res.data) {
      toast.success(<SuccessToast />, ToastConfig)
      return res.data
    } else {
      return res.data
    }
  },

  //  ------------------------------------------------------------------

  // Currency
  getAllCurrency: async (data) => {
    Root.show()
    const rdata = await ConfigApi.getAllCurrency(data)
    Root.hide()
    if (rdata) {
      return rdata.data
    } else {
      return dummyData
    }
  },
  submitCurrency: async (data) => {
    Root.show()
    const res = await ConfigApi.submitCurrency(data)
    Root.hide()
    if (res) {
      return true
    } else {
      return false
    }
  },
  submitRakeBackUpdate: async (data) => {
    Root.show()
    const res = await ConfigApi.submitRakeBackUpdate(data)
    Root.hide()
    if (res) {
      return true
    } else {
      return false
    }
  },
  submitCurrencyOrder: async (data) => {
    Root.show()
    const res = await ConfigApi.submitCurrencyOrder(data)
    Root.hide()
    if (res.data) {
      toast.success(<SuccessToast />, ToastConfig)
      return res.data
    } else {
      return res.data
    }
  },
  downloadCSVCurrency: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadCSVCurrency(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },
  downloadEXCELCurrency: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadEXCELCurrency(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },

  //  ------------------------------------------------------------------

  // Country
  getAllCountry: async (data) => {
    Root.show()
    const res = await ConfigApi.getAllCountry(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return dummyData
    }
  },
  submitCountry: async (data) => {
    Root.show()
    const res = await ConfigApi.submitCountry(data)
    Root.hide()
    if (res) {
      toast.success(<SuccessToast />, ToastConfig)
      return true
    } else {
      return false
    }
  },
  downloadCSVCountry: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadCSVCountry(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },
  downloadEXCElCountry: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadEXCElCountry(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },

  //  ------------------------------------------------------------------

  // Language
  getAllLanguage: async (data) => {
    Root.show()
    const res = await ConfigApi.getAllLanguage(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return dummyData
    }
  },
  getAllLanguageList: async (data) => {
    Root.show()
    const res = await ConfigApi.getAllLanguageList(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return []
    }
  },
  submitLanguage: async (data) => {
    Root.show()
    const flag = await ConfigApi.submitLanguage(data)
    Root.hide()
    if (flag) {
      toast.success(<SuccessToast />, ToastConfig)
      return true
    } else {
      return false
    }
  },
  downloadCSVLanguage: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadCSVLanguage(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },
  downloadEXCELLanguage: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadEXCELLanguage(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },

  getMultilang: async () => {
    const rdata = await ConfigApi.getMultilang()
    if (rdata) {
      return rdata.data
    } else {
      return []
    }
  },

  //special game type
  submitSpecialGameType: async (data) => {
    Root.show()
    const res = await ConfigApi.submitSpecialGameType(data)
    Root.hide()
    if (res) {
      toast.success(<SuccessToast />, ToastConfig)
      return true
    } else {
      return false
    }
  },
  getAllSpecialGameType: async (data) => {
    Root.show()
    const res = await ConfigApi.getAllSpecialGameType(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return dummyData
    }
  },
  deleteSpecialGameType: async (data) => {
    Root.show()
    const res = await ConfigApi.deleteSpecialGameType(data)
    Root.hide()
    if (res) {
      return true
    } else {
      return false
    }
  },
  downloadCSVSpecialGameTypes: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadCSVSpecialGameTypes(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },
  downloadEXCELSpecialGameTypes: async (data) => {
    Root.show()
    const res = await ConfigApi.downloadEXCELSpecialGameTypes(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return false
    }
  },

  getAllBlockchainNetworkList: async (data) => {
    Root.show()
    const res = await ConfigApi.getAllBlockchainNetworkList(data)
    Root.hide()
    if (res) {
      return res.data
    } else {
      return dummyData
    }
  }
  //--------------------------------------------------------------------

}))

export default useStore
