import create from "zustand"
import AuthApi from "../api/AuthApi"
import CMSApi from "../api/CMSApi"
import ProfileApi from "../api/ProfileApi"
import { toast } from 'react-toastify'
import { LoginToastContent } from "@src/views/ui-elements/ToastContents"
import ToastConfig from "@src/configs/toastConfig"
import { Root } from "../configs/serverConfig"

const useStore = create((set) => ({
  Auth: {
    isAuth: false,
    isChecked: false,
    user: {}
  },
  sidebar: [],
  currencies: [],
  logoImage: "",
  userLogin: async (data) => {
    Root.show()
    const res = await AuthApi.userLogin(data)
    Root.hide()
    if (res) {
      const { token, user } = res.data
      AuthApi.setToken(token)
      toast.success(<LoginToastContent name={user.username} />, ToastConfig)
      setTimeout(() => {
        window.location.assign("/dashboard")
      }, 500)
    }
  },
  sessionCheck: async () => {
    const init = async () => {
      Root.show()
      const configData = await CMSApi.getAllConfig()
      Root.hide()
      if (configData) {
        const logoData = configData.data.find(item => item.key === "logo")?.value || ""
        const faviconData = configData.data.find(item => item.key === "favicon")?.value || ""
        const adminNameData = configData.data.find(item => item.key === "adminName")?.value || Root.site
        const link = document.querySelector("link[rel~='icon']")
        if (!link) {
          link = document.createElement('link')
          link.rel = 'icon'
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        document.title = adminNameData
        if (logoData) {
          set({ logoImage: `${Root.serverUrl}${logoData}` })
        } else {
          set({ logoImage: `${Root.siteUrl}/image/site/logo/logo.png` })
        }
        if (faviconData) {
          link.href = `${Root.serverUrl}${faviconData}`
        } else {
          link.href = `${Root.siteUrl}/image/site/logo/favicon.ico`
        }
      }
    }
    init()

    if (AuthApi.getToken()) {
      Root.show()
      const sideData = await ProfileApi.getAllAdminSidebar()
      const res = await AuthApi.sessionCheck()
      Root.hide()

      console.log(res.data)

      if (res) {
        set({
          Auth: {
            isAuth: true,
            user: res.data,
            isChecked: true
          },
          currencies: res.data.currencies,
          sidebar: sideData.data
        })

      } else {
        AuthApi.clearToken()
        setTimeout(() => {
          window.location.reload()
        }, 100)
      }
    } else {
      set({ Auth: { isAuth: false, user: {}, isChecked: true } })
    }
  },
  sendLogout: async () => {
    Root.show()
    await AuthApi.sendLogout()
    Root.hide()
    AuthApi.clearToken()
    window.location.assign("/login")
  },
  forgotPasswordRequest: async (data) => {
    Root.show()
    const flag = await AuthApi.forgotPasswordRequest(data)
    Root.hide()
    if (flag) {
      toast.success("reset password sent")
    }
  }

}))

export default useStore
