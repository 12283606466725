const site = 'betatest123.com'

const keys = {
    "test108.com": "6Lf2cUIhAAAAAHVBDaJvBkguUWQCx5Vfpt8sXd-c",
    "slotmaniax.com": "6LfmlHEiAAAAAL-N996czhP7QotX30B3dEThHWj6",
    "8866casino.net": "6LciyXIgAAAAANpcp3v0g0U1CPOU02gBv0mrbVV8",
    "123street.co": "6LfV_8QhAAAAAH-8bEvtaEPZ-bbjDPdp4HQNcsOR",
    "betstar1.com": "6LdSECshAAAAAIic0ug8obQVkGofka3ETS4ZqE4O"
}

const agent = !(site === 'betatest123.com' || site === 'slotmaniax.com')

export const Root = process.env.NODE_ENV === "development" ? {
    serverUrl: "http://localhost:2000",
    sportUrl: "http://localhost:2004",
    siteUrl: "http://localhost:3002",
    // siteUrl: "http://localhost:2022",
    prositeUrl: "http://localhost:2001",
    token: "admin-test108.com-token",

    // serverUrl: `https://admin.${site}`,
    // sportUrl: `https://sport.${site}`,
    // siteUrl: `https://${site}`,
    // prositeUrl: `https://promotion.${site}`,
    // token: `admin-${site}-token`,

    agent,
    site,
    siteKey: keys[site],
    show: null,
    hide: null
} : {
    serverUrl: `https://admin.betatest123.com`,
    sportUrl: `https://sport.betatest123.com`,
    siteUrl: `https://betatest123.com`,
    prositeUrl: `https://promotion.betatest123.com`,
    token: `admin-betatest123.com-token`,

    agent,
    site,
    siteKey: keys[site],
    show: null,
    hide: null
}
