import APIAbstract from "./APIAbstract"
import { Root } from "../configs/serverConfig"

class ConfigApi extends APIAbstract {

  // Game Type
  getAllGameType(data) {
    return this.post("/admin/config/getAllGameType", data)
  }
  submitGameType(data) {
    return this.post("/admin/config/submitGameType", data)
  }
  submitGameTypeOrder(data) {
    return this.post("/admin/config/submitGameTypeOrder", data)
  }
  downloadCSVGameType(data) {
    return this.post("/admin/config/downloadCSVGameType", data)
  }
  downloadEXCELGameType(data) {
    return this.post("/admin/config/downloadEXCELGameType", data)
  }

  //  ------------------------------------------------------------------

  // Game Category
  getAllGameCategories(data) {
    return this.post("/admin/config/getAllGameCategories", data)
  }
  submitGameCategory(data) {
    return this.post("/admin/config/submitGameCategory", data)
  }
  submitGameCategoryOrder(data) {
    return this.post("/admin/config/submitGameCategoryOrder", data)
  }
  downloadCSVGameCategories(data) {
    return this.post("/admin/config/downloadCSVGameCategories", data)
  }
  downloadEXCELGameCategories(data) {
    return this.post("/admin/config/downloadEXCELGameCategories", data)
  }

  //  ------------------------------------------------------------------

  // Game Provider
  getAllGameTypesOfProvider(data) {
    return this.post("/admin/config/getAllGameTypesOfProvider", data)
  }
  getAllGameAgregator(data) {
    return this.post("/admin/config/getAllGameAgregator", data)
  }
  getAllProvider(data) {
    return this.post("/admin/config/getAllProvider", data)
  }
  submitGameProvider(data) {
    return this.post("/admin/config/submitGameProvider", data)
  }
  submitGameProviderOrder(data) {
    return this.post("/admin/config/submitGameProviderOrder", data)
  }
  downloadCSVGameProvider(data) {
    return this.post("/admin/config/downloadCSVGameProvider", data)
  }
  downloadEXCELGameProvider(data) {
    return this.post("/admin/config/downloadEXCELGameProvider", data)
  }

  getCurrencyList(data) {
    return this.post("/admin/config/getCurrencyList", data)
  }
  getAllPriceList(data) {
    return this.post("/admin/config/getAllPriceList", data)
  }
  getCountryList(data) {
    return this.post("/admin/config/getCountryList", data)
  }

  //  ------------------------------------------------------------------

  // Games
  getAllGameProvidersOfGames(data) {
    return this.post("/admin/config/getAllGameProvidersOfGames", data)
  }
  getConditionProviders(data) {
    return this.post("/admin/config/getConditionProviders", data)
  }
  getAllGames(data) {
    return this.post("/admin/config/getAllGames", data)
  }
  submitGame(data) {
    return this.post("/admin/config/submitGame", data)
  }
  downloadCSVGames(data) {
    return this.post("/admin/config/downloadCSVGames", data)
  }
  downloadEXCELGames(data) {
    return this.post("/admin/config/downloadEXCELGames", data)
  }
  getAllLocalTournamentList(data) {
    return this.post("/admin/config/getAllLocalTournamentList", data)
  }

  getAllSpecialGameTypeList(data) {
    return this.post("/admin/config/getAllSpecialGameTypeList", data)
  }
  submitGameOrder(data) {
    return this.post("/admin/config/submitGameOrder", data)
  }

  //  ------------------------------------------------------------------

  // Currency
  getAllCurrency(data) {
    return this.post("/admin/config/getAllCurrency", data)
  }
  submitCurrency(data) {
    return this.post("/admin/config/submitCurrency", data)
  }
  submitRakeBackUpdate(data) {
    return this.post("/admin/config/submitRakeBackUpdate", data)
  }
  submitCurrencyOrder(data) {
    return this.post("/admin/config/submitCurrencyOrder", data)
  }
  downloadCSVCurrency(data) {
    return this.post("/admin/config/downloadCSVCurrency", data)
  }
  downloadEXCELCurrency(data) {
    return this.post("/admin/config/downloadEXCELCurrency", data)
  }

  //  ------------------------------------------------------------------

  // Country
  getAllCountry(data) {
    return this.post("/admin/config/getAllCountry", data)
  }
  submitCountry(data) {
    return this.post("/admin/config/submitCountry", data)
  }
  downloadCSVCountry(data) {
    return this.post("/admin/config/downloadCSVCountry", data)
  }
  downloadEXCElCountry(data) {
    return this.post("/admin/config/downloadEXCElCountry", data)
  }

  //  ------------------------------------------------------------------

  // Language
  getAllLanguage(data) {
    return this.post("/admin/config/getAllLanguage", data)
  }
  getAllLanguageList(data) {
    return this.post("/admin/config/getAllLanguageList", data)
  }
  submitLanguage(data) {
    return this.post("/admin/config/submitLanguage", data)
  }
  downloadCSVLanguage(data) {
    return this.post("/admin/config/downloadCSVLanguage", data)
  }
  downloadEXCELLanguage(data) {
    return this.post("/admin/config/downloadEXCELLanguage", data)
  }

  getMultilang() {
    return this.post('/admin/config/getMultilangData')
  }

  // special game type
  submitSpecialGameType(data) {
    return this.post("/admin/config/submitSpecialGameType", data)
  }

  getAllSpecialGameType(data) {
    return this.post("/admin/config/getAllSpecialGameType", data)
  }

  deleteSpecialGameType(data) {
    return this.post("/admin/config/deleteSpecialGameType", data)
  }

  downloadCSVSpecialGameTypes(data) {
    return this.post("/admin/config/downloadCSVSpecialGameTypes", data)
  }
  downloadEXCELSpecialGameTypes(data) {
    return this.post("/admin/config/downloadEXCELSpecialGameTypes", data)
  }

  getAllBlockchainNetworkList(data) {
    return this.post("/admin/config/getAllBlockchainNetworkList", data)
  }
  //----------------------------------------------

}
export default new ConfigApi(`${Root.serverUrl}`)
