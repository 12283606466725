import APIAbstract from "./APIAbstract"
import { Root } from "../configs/serverConfig"

class CMSApi extends APIAbstract {

  // Support Type
  getAllSupportType(data) {
    return this.post("/admin/cms/getAllSupportType", data)
  }
  submitSupportType(data) {
    return this.post("/admin/cms/submitSupportType", data)
  }
  downloadCSVSupportType(data) {
    return this.post("/admin/cms/downloadCSVSupportType", data)
  }
  ownloadEXCELSupportType(data) {
    return this.post("/admin/cms/ownloadEXCELSupportType", data)
  }
  //  ------------------------------------------------------------------

  // Support Data
  getAllSupportTypeList(data) {
    return this.post("/admin/cms/getAllSupportTypeList", data)
  }
  getAllSupportData(data) {
    return this.post("/admin/cms/getAllSupportData", data)
  }
  submitSupportData(data) {
    return this.post("/admin/cms/submitSupportData", data)
  }
  downloadCSVSupportQA(data) {
    return this.post("/admin/cms/downloadCSVSupportQA", data)
  }
  downloadEXCELSupportQA(data) {
    return this.post("/admin/cms/downloadEXCELSupportQA", data)
  }

  //  ------------------------------------------------------------------

  // Site Config
  getAllConfig(data) {
    return this.post("/admin/cms/getAllConfig", data)
  }
  submitLogo(data) {
    return this.post("/admin/cms/submitLogo", data)
  }
  submitFavicon(data) {
    return this.post("/admin/cms/submitFavicon", data)
  }
  submitTerms(data) {
    return this.post("/admin/cms/submitTerms", data)
  }
  submitRegiBanner(data) {
    return this.post("/admin/cms/submitRegiBanner", data)
  }
  submitCallBell(data) {
    return this.post("/admin/cms/submitCallBell", data)
  }
  submitGS(data) {
    return this.post("/admin/cms/submitGS", data)
  }
  submitAdminName(data) {
    return this.post("/admin/cms/submitAdminName", data)
  }
  submitPlayerName(data) {
    return this.post("/admin/cms/submitPlayerName", data)
  }
  submitRakeBack(data) {
    return this.post("/admin/config/submitRakeBack", data)
  }

  //  ------------------------------------------------------------------

  // Slider
  getAllSlider(data = {}) {
    return this.post("/admin/cms/getAllSlider", data)
  }
  submitSlider(data) {
    return this.post("/admin/cms/submitSlider", data)
  }
  submitSliderOrder(data) {
    return this.post("/admin/cms/submitSliderOrder", data)
  }
  downloadCSVMainSlider(data = {}) {
    return this.post("/admin/cms/downloadCSVMainSlider", data)
  }
  downloadEXCELMainSlider(data = {}) {
    return this.post("/admin/cms/downloadEXCELMainSlider", data)
  }
  deleteMainSlider(data = {}) {
    return this.post("/admin/cms/deleteMainSlider", data)
  }

  //  ------------------------------------------------------------------

  // Poker Slider
  getAllPokerSlider(data = {}) {
    return this.post("/admin/cms/getAllPokerSlider", data)
  }
  submitPokerSlider(data) {
    return this.post("/admin/cms/submitPokerSlider", data)
  }
  submitPokerSliderOrder(data) {
    return this.post("/admin/cms/submitPokerSliderOrder", data)
  }
  downloadCSVPokerSlider(data = {}) {
    return this.post("/admin/cms/downloadCSVPokerSlider", data)
  }
  downloadEXCELPokerSlider(data = {}) {
    return this.post("/admin/cms/downloadEXCELPokerSlider", data)
  }

  //  ------------------------------------------------------------------

  // Multi Language
  getMultiLang(data) {
    return this.post("/admin/cms/getMultiLang", data)
  }
  deleteMultiLang(data) {
    return this.post("/admin/cms/deleteMultiLang", data)
  }
  submitMultiLang(data) {
    return this.post("/admin/cms/submitMultiLang", data)
  }
  getAllLanguageList(data) {
    return this.post("/admin/cms/getAllLanguageList", data)
  }
  downloadCSVMultiLang(data) {
    return this.post("/admin/cms/downloadCSVMultiLang", data)
  }
  downloadEXCELEmailTemplate(data) {
    return this.post("/admin/cms/downloadEXCELEmailTemplate", data)
  }
  downloadEXCELMultiLang(data) {
    return this.post("/admin/cms/downloadEXCELMultiLang", data)
  }
  uploadCSVMultiLang(data) {
    return this.post("/admin/cms/uploadCSVMultiLang", data)
  }
  
  //  ------------------------------------------------------------------
  // admin panel multi language
  getAdminMultiLang(data) {
    return this.post("/admin/cms/getAdminMultiLang", data)
  }
  submitAdminMultiLang(data) {
    return this.post("/admin/cms/submitAdminMultiLang", data)
  }
  deleteAdminMultiLang(data) {
    return this.post("/admin/cms/deleteAdminMultiLang", data)
  }
  downloadCSVAdminMultiLang(data) {
    return this.post("/admin/cms/downloadCSVAdminMultiLang", data)
  }
  downloadEXCELAdminMultiLang(data) {
    return this.post("/admin/cms/downloadEXCELAdminMultiLang", data)
  }
  uploadCSVAdminMultiLang(data) {
    return this.post("/admin/cms/uploadCSVAdminMultiLang", data)
  }
  //---------------------------------------------------------------------
  // Sidebar Base Menu
  getAllSidebarBaseMenu(data) {
    return this.post("/admin/cms/getAllSidebarBaseMenu", data)
  }
  submitSidebarBaseMenu(data) {
    return this.post("/admin/cms/submitSidebarBaseMenu", data)
  }
  submitSidebarBaseMenuOrder(data) {
    return this.post("/admin/cms/submitSidebarBaseMenuOrder", data)
  }
  downloadCSVBaseSidebar(data) {
    return this.post("/admin/cms/downloadCSVBaseSidebar", data)
  }
  downloadEXCELBaseSidebar(data) {
    return this.post("/admin/cms/downloadEXCELBaseSidebar", data)
  }

  //  ------------------------------------------------------------------

  // Sidebar Main Menu
  getAllSidebarBaseMenuList(data) {
    return this.post("/admin/cms/getAllSidebarBaseMenuList", data)
  }
  getAllSidebarMainMenu(data) {
    return this.post("/admin/cms/getAllSidebarMainMenu", data)
  }
  submitSliderMainMenuItem(data) {
    return this.post("/admin/cms/submitSliderMainMenuItem", data)
  }
  submitSidebarMainMenuOrder(data) {
    return this.post("/admin/cms/submitSidebarMainMenuOrder", data)
  }
  downloadCSVMainSidebar(data) {
    return this.post("/admin/cms/downloadCSVMainSidebar", data)
  }
  downloadEXCELMainSidebar(data) {
    return this.post("/admin/cms/downloadEXCELMainSidebar", data)
  }
  //  ------------------------------------------------------------------

  // Footer Menu Manage
  getAllFooterMenu(data) {
    return this.post("/admin/cms/getAllFooterMenu", data)
  }
  subsubmitFooterMenu(data) {
    return this.post("/admin/cms/subsubmitFooterMenu", data)
  }
  submitFooterMenuOrder(data) {
    return this.post("/admin/cms/submitFooterMenuOrder", data)
  }
  downloadCSVFooterManage(data) {
    return this.post("/admin/cms/downloadCSVFooterManage", data)
  }
  downloadEXCELFooterManage(data) {
    return this.post("/admin/cms/downloadEXCELFooterManage", data)
  }

  //---------------------------------------
  // Game Management
  submitHotGameOrder(data) {
    return this.post("/admin/cms/submitHotGameOrder", data)
  }

  //Game Slider
  getAllGameSlider(data = {}) {
    return this.post("/admin/cms/getAllGameSlider", data)
  }
  submitGameSlider(data) {
    return this.post("/admin/cms/submitGameSlider", data)
  }
  submitGameSliderOrder(data) {
    return this.post("/admin/cms/submitGameSliderOrder", data)
  }
  downloadCSVGameSlider(data = {}) {
    return this.post("/admin/cms/downloadCSVGameSlider", data)
  }
  downloadEXCELGameSlider(data = {}) {
    return this.post("/admin/cms/downloadEXCELGameSlider", data)
  }
  deleteGameSlider(data = {}) {
    return this.post("/admin/cms/deleteGameSlider", data)
  }
  getGameMenus(data = {}) {
    return this.post("/admin/cms/getGameMenus", data)
  }


}
export default new CMSApi(`${Root.serverUrl}`)
