import { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AuthZustand from "@src/zustand/AuthZustand"
import { LoadingContext } from "@src/utility/context/loadingContext"
import { Root } from "@src/configs/serverConfig"
import ConfigZustand from "@src/zustand/ConfigZustand"

const AuthProvider = ({ children }) => {
    const { i18n } = useTranslation()
    const { sessionCheck } = AuthZustand((state) => state)
    const { getMultilang } =  ConfigZustand((state) => state) 
    const { showLoading, hideLoading } = useContext(LoadingContext)

    const [flag, setFlag] = useState(false)


    useEffect(() => {
        Root.show = showLoading
        Root.hide = hideLoading
        const init = async () => {
            const langData = await getMultilang()
            for (let i = 0; i < langData.langs.length; i++) {
                i18n.addResourceBundle(
                    langData.langs[i].language,
                    'translation',
                    langData.data[langData.langs[i].language]
                )
            }
        await sessionCheck()
            setFlag(true)
        }
        init()
    }, [])


    return (
        <>
            {flag ? children : ""}
        </>
    )
}

export default AuthProvider