export const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
]

export const statusOptions = [
    { value: 'allow', label: 'Allowed' },
    { value: 'pending', label: 'Pending' },
    { value: 'block', label: 'Blocked' }
]

export const gameStatusOptions = [
    { value: "", label: "All" },
    { value: "true", label: 'Allowed' },
    { value: "false", label: 'Blocked' }
]

export const sportLimitOptions = [
    { value: "all", label: "All" },
    { value: "before48", label: 'Up to 48 hours before' },
    { value: "48to24", label: '48 hours - 24 hours' },
    { value: "after24", label: 'Last 24 hours' }
]

export const affiliateOptions = [
    { value: "", label: "All" },
    { value: "true", label: "True" },
    { value: "false", label: "False" }
]

export const gameOptions = [
    { value: "", label: "All" },
    { value: "isnew", label: 'New Game' },
    { value: "isfeatured", label: 'Featured Games' },
    { value: "desktop", label: 'Desktop Game' },
    { value: "mobile", label: 'Mobile Game' },
    { value: "isTournament", label: 'Tournament Games' }
]

export const pagenationOptions = [10, 25, 50, 100]
export const PlayerPermission = "6023faa5cd0b2e2e58bcdfad"
export const SuperAgentUser = "313539393732303631363438"
export const SuperAgentUserPermission = "6023f61bee918d2e94b8c76d"

export const statusObj = {
    pending: 'light-warning',
    allow: 'light-success',
    block: 'light-danger'
}

export const SimpleStatus = {
    true: 'light-success',
    false: 'light-danger'
}

export const userType = [
    { value: "", label: "All" },
    { value: "agent", label: "AGENT" },
    { value: "player", label: "PLAYER" }
]

export const CRMUserType = [
    { value: "agent", label: "AGENT" },
    { value: "player", label: "PLAYER" }
]

export const CRMMethod = [
    { value: "site", label: "Website" },
    { value: "email", label: "Email" },
    { value: "sms", label: "SMS" }
]

export const paymentType = [
    { value: "", label: "All" },
    { value: "deposit", label: "Deposit" },
    { value: "withdraw", label: "Withdraw" }
]

export const payDecision = [
    { value: "", label: "Please Select" },
    { value: "allow", label: "allowed" },
    { value: "block", label: "blocked" }
]

export const payPendingDecision = [
    { value: "", label: "Please Select" },
    { value: "approve", label: "approve" },
    { value: "reject", label: "reject" }
]

export const typeData = [
    { value: "fiat", label: "Fiat" },
    { value: "crypto", label: "Crypto" }
]

export const disGameData = [
    { value: true, label: "Enable" },
    { value: false, label: "Disable" }
]

export const activeData = [
    { value: true, label: "Activated" },
    { value: false, label: "UnActivated" }
]

export const deviceList = [
    { label: "Desktop", value: "desktop" },
    { label: "Mobile", value: "mobile" }
]

export const dummyData = {
    data: [],
    totalPages: 0,
    totalCount: 0,
    totalDWData: []
}

export const IQSportStatus = [
    { value: 0, label: "All" },
    { value: 1, label: "Uncalculated" },
    { value: 2, label: "Won" },
    { value: 3, label: "Lost" },
    { value: 4, label: "Deleted" },
    { value: 5, label: "Cashouted" },
    { value: 6, label: "Returned" },
    { value: 7, label: "NotAccepted" }
]

export const IQSportLive = [
    { value: "", label: "All" },
    { value: "true", label: "Live" },
    { value: "false", label: "Pre" }
]

export const IQSportType = [
    { value: 0, label: "All" },
    { value: 1, label: "Single" },
    { value: 2, label: "Multi" }
]

export const BetType = [
    { value: "", label: "All" },
    { value: "BET", label: "Bet" },
    { value: "WIN", label: "Win" }
]

export const paymentFilterType = [
    { value: "", label: "All" },
    { value: "DEPOSIT", label: "Deposit" },
    { value: "WITHDRAWL", label: "Withdraw" }
]

export const paymentStatus = [
    { value: "", label: "All" },
    { value: "success", label: "Success" },
    { value: "pending", label: "Pending" },
    { value: "failure", label: "Failure" },
    { value: "waiting_confirm", label: "Waiting" }
]

export const BonusTypes = [
    { value: "", label: "All" },
    { value: "WIN", label: "Credit" },
    { value: "BET", label: "Debit" }
]

export const sportStatusObj = [
    { value: "", label: "All" },
    { value: "0", label: "NotStarted" },
    { value: "1", label: "Started" },
    { value: "2", label: "Finished" },
    { value: "3", label: "Canceled" },
    { value: "4", label: "Deleted" },
    { value: "5", label: "Unknown" },
    { value: "6", label: "Resulted" },
    { value: "7", label: "Displayed" }
]

export const IQSportBetType = {
    1: "Single",
    2: "Multi",
    3: "System"
}

export const chainDataList = [
    {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        rpcUrls: ['https://eth.llamarpc.com'],
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        blockExplorerUrls: ['https://etherscan.io/']
    },
    {
        chainId: '0x38',
        chainName: 'Binance Smart Chain Mainnet',
        rpcUrls: ['https://bsc.publicnode.com'],
        nativeCurrency: {
            name: 'Binance Smart Coin',
            symbol: 'BNB',
            decimals: 18
        },
        blockExplorerUrls: ['https://bscscan.com/']
    },
    
    {
        chainId: '0xa86a',
        chainName: 'Avalanche',
        rpcUrls: ['https://avalanche.drpc.org'],
        nativeCurrency: {
            name: 'AVAX',
            symbol: 'AVAX',
            decimals: 18
        },
        blockExplorerUrls: ['https://snowtrace.io/']
    },
    
    {
        chainId: '0xfa',
        chainName: 'Fantom Opera',
        rpcUrls: ['https://fantom.drpc.org'],
        nativeCurrency: {
            name: 'FTM',
            symbol: 'FTM',
            decimals: 18
        },
        blockExplorerUrls: ['https://ftmscan.com/']
    },
    
    {
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        rpcUrls: ['https://polygon-pokt.nodies.app'],
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
        },
        blockExplorerUrls: ['https://polygonscan.com/']
    },
    
    {
        chainId: '0x44d',
        chainName: 'Polygon zkEVM',
        rpcUrls: ['https://polygon-zkevm.drpc.org'],
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        blockExplorerUrls: ['https://zkevm.polygonscan.com/']
    },
    
    {
        chainId: '0xa4b1',
        chainName: 'Arbitrum One',
        rpcUrls: ['https://rpc.arb1.arbitrum.gateway.fm'],
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        blockExplorerUrls: ['https://arbiscan.io/']
    },
    
    {
        chainId: '0x13881',
        chainName: 'Polygin Testnet',
        rpcUrls: ['https://polygon-mumbai.drpc.org'],
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
        },
        blockExplorerUrls: ['https://mumbai.polygonscan.com/']
    }
]
