import axios from "axios"
import { toast } from 'react-toastify'
import { ApiErrorToast } from '@src/views/ui-elements/ToastContents'
import ToastConfig from "@src/configs/toastConfig"
import { Root } from "../configs/serverConfig"

const msgData = {
  last: "",
  timestamp: ""
}

class APIAbstract {
  
  constructor(apiEndpoint) {
    axios.interceptors.request.use(
      config => {
        config.baseURL = apiEndpoint
        config.headers = { authorization: `${this.getToken()}` }
        return config
      },
      error => Promise.reject(error)
    )
    axios.interceptors.response.use(
      response => response,
      error => {
        const { response } = error
        const timestamp = new Date().valueOf()
        if (response && (msgData.last !== response.data || (msgData.last === response.data && timestamp - msgData.timestamp > 1000))) {
          msgData.last = response.data
          msgData.timestamp = timestamp
          if (response.status === 401) {
            toast.error(<ApiErrorToast title={response.data} />, ToastConfig)
            this.clearToken()
            setTimeout(() => {
              window.location.reload()
            }, 100)
          }
          if (response.status === 404) {
            toast.error(<ApiErrorToast title={"Not found endpoint"} />, ToastConfig)
          } else if (response.status === 405) {
            window.location.assign('/blocked')
          } else if (response.status === 400 || response.status === 403 || response.status === 500 || response.status === 429 || response.status === 413) {
            toast.error(<ApiErrorToast title={response.data} />, ToastConfig)
          } else {
            console.log(response)
          }
          return false
        }
        return Promise.reject(error)
      }
    )
  }

  objectToQueryString(obj) {
    const str = []
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent((obj[p]))}`)
      }
    }
    return str.join("&")
  }

  async get(url, data = {}) {
    return axios.get(`${url}?${this.objectToQueryString(data)}`)
  }

  async post(url, data = {}) {
    return axios.post(`${url}`, data)
  }

  async put(url, data = {}) {
    return axios.put(`${url}`, data)
  }

  async delete(url, data = {}) {
    return axios.delete(`${url}?${this.objectToQueryString(data)}`)
  }

  getToken() {
    const data = window.localStorage.getItem(Root.token)
    return data ? data : false
  }

  setToken(token) {
    window.localStorage.setItem(Root.token, token)
  }

  clearToken() {
    window.localStorage.removeItem(Root.token)
  }

}

export default APIAbstract
